  .calculate{
    display: block;
    max-width: 480px;
    margin: 0 auto;
    background: #fcf4e8;
  }  
  
    h3{
      font-weight: bold;
   }

    p{

    }

    .calculate-image-container{
      padding-top: 50%;
      position: relative;
      text-align: center;
    }  

    .calculate-ing{
      position: relative;
      text-align: center;

    } 

    .loading {
      position: relative;
        padding-top : 5%;
        clip-path: inset(0 1ch 0 0);
        animation: l 1s steps(4) infinite;
      }
      
      @keyframes l {
        to {
          clip-path: inset(0 -1ch 0 0)
        }
      }
