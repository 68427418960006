body{
    background-color: #fcf4e8;
}  



h3{
    font-family: "IBM Plex Sans KR", sans-serif;
    font-weight: 700;
    font-size: 2.5em;
  }

p{
    font-family: "IBM Plex Sans KR", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: x-large;
}

