.final-container{
    display: block;
    text-align: center;
    max-width: 480px;
    margin: 0 auto;
  }  

 .bible-container{
    position:relative;
    text-align: center;

 }

.bible{
    
    text-align: center;
    font-family:'GangwonEdu_OTFBoldA' ;
    font-size: 16px;
    white-space: pre-wrap;
    padding-bottom: 5%;
}   

.bible-sword{
    position: relative;
    text-align: center;
    font-family:'GangwonEdu_OTFBoldA';
    font-size: 1.2rem;
    padding-top: 5%;    
    padding-left: 5%;
    padding-right: 5%;
}   


.bible-section{
    position: relative;
    text-align: center;
    font-family:'GangwonEdu_OTFBoldA' ;
  
  }
  

.download-container{
    position: relative;
    text-align: center;
    padding-bottom: 5%;
}    

.download-button {
    display: inline-block;
    padding: 5px 20px;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    color: white;
    background-color: #4b1360;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family:'GangwonEdu_OTFBoldA' ;
  }
  
  .listen-button {
    display: inline-block;
    padding: 5px 20px;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    color: white;
    background-color: #4b1360;
    width: 125.9px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family:'GangwonEdu_OTFBoldA' ;
  }

#final h3{
    font-size: small;
    }

#final h2{
    font-size: small;
    font-family: "GangwonEdu_OTFBoldA";}

    #final h2, #final p {
        margin-top: -5px; /* 원하는 크기로 상단 마진 조절 */
    }
    .phoneBG {
        margin-top: -10px; /* 원하는 크기로 상단 마진 조절 */
    }

.resultTop{
    width: 100%;
}

.last-button-container{
    position: relative;
    text-align: center;
    padding-top: 10%; 
   
}

.last-button{
    width: 16rem;
    padding: 5px 10px;
    font-family:'GangwonEdu_OTFBoldA';
    font-weight: 500;
    font-size: 20px;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    color: white;
    background-color: #4b1360;
    border: 2px solid #fdf6ff;
    border-radius: 40px;
    line-height: 35px;
    margin-bottom: 2%;
  }

  .download-button:active {
    background-color: #BBA9D9;
    color: #000;
  }    

  .last-button:active {
    background-color: #BBA9D9;
    color: #000;
  }    

  .last-button-instagram:active {
    background-color: #BBA9D9;
    color: #000;
  }   

  .last-button-instagram{
    width: 16rem;
    padding: 5px 10px;
    font-family:'GangwonEdu_OTFBoldA';
    font-weight: 500;
    font-size: 20px;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    color: #4b1360;
    background-color: #fdf6ff;
    border: 1px solid #4b1360;
    border-radius: 40px;
    line-height: 35px;
    margin-bottom: 2%;
  }


@font-face {
    font-family: "Dovemayo_gothic";
    font-weight: normal;
    src: url("../fonts/Dovemayo_gothic.ttf") format("truetype");
    }    

    @font-face {
        font-family: 'GangwonEdu_OTFBoldA';
        src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2201-2@1.0/GangwonEdu_OTFBoldA.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
    .share-box{
        position: relative;
        text-align: center;
        margin: 15%;
    }
    .share{
        display: flex;
        text-align: center;
        justify-content: space-evenly;
    }
    


   
    .final-container .logo-container {
        position:relative;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
        width:200px;
        padding-bottom: 20%;
    }

      
    .logo-image {
        width: 100px; /* 로고 이미지의 너비를 200px로 설정 */
        height: auto; /* 비율을 유지한 채로 높이를 자동 조정 */
        margin-top: 35px;
      }    
      