/*#main{background-color: #fcf4e8;
width: 90%;
text-align: center;
}
*/
h3{
  font-weight: bold;
  

}

.container{ 
  display: block;
  max-width: 480px;
  margin: 0 auto;
  text-align: center;
  background: #fcf4e8;
  margin-top: 10%;
}  

  button {
    display: inline-block;
    padding: 5px 20px;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    color: white;
    background-color: #4b1360;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  

    .container .logo-container {
        position: relative;
        left: 0;
        right: 0;
        padding-top: 10%;
        text-align: center;
        width: 200px;
        margin: 0 auto;
    }
    
   